<template>
    <div>
        <vs-table
            :onlyClickCheckbox="true"
            ref="table"
            v-model="selected"
            :sst="true"
            search
            :data="accounts"
            @sort="handleSort"
            @search="search"
        >

            <div slot="header" class="flex items-center flex-grow justify-end">
                <p v-show="searchQuery" class="mr-4 mb-4">
                    Search results for '{{ searchQuery }}'
                </p>

                <div class="flex mr-4 mb-4 ">
                    <vs-button
                        icon-pack="feather"
                        icon="icon-download"
                        class="rounded-full mr-3"
                        title="Download client list .csv"
                        @click="downloadClients"
                    />
                    <div>
                        <DatePicker
                            style="z-index: 10000"
                            mode="range"
                            v-model="range"
                        >
                            <!--                <template #default="{ togglePopover, inputValue, inputEvents }">-->
                            <!--                    <div-->
                            <!--                        class="flex rounded-lg border border-gray-300 dark:border-gray-600 overflow-hidden"-->
                            <!--                    >-->
                            <!--                        <button-->
                            <!--                            class="flex justify-center items-center px-2 bg-accent-100 hover:bg-accent-200 text-accent-700 border-r border-gray-300 dark:bg-gray-700 dark:text-accent-300 dark:border-gray-600 dark:hover:bg-gray-600"-->
                            <!--                            @click="() => togglePopover()"-->
                            <!--                        >-->
                            <!--                        </button>-->
                            <!--                        <input-->
                            <!--                            :value="inputValue"-->
                            <!--                            v-on="inputEvents"-->
                            <!--                            class="flex-grow px-2 py-1 bg-white dark:bg-gray-700"-->
                            <!--                        />-->
                            <!--                    </div>-->
                            <!--                </template>-->
                        </DatePicker>
                    </div>
                </div>

                <!-- Filter -->

                <vs-dropdown
                    vs-trigger-click
                    class="cursor-pointer mb-4 mr-4 items-per-page-handler"
                >
                    <div
                        class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                    >
                        <span class="mr-2">Filter - {{ filterLabel }}</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>
                    <vs-dropdown-menu>
                        <vs-dropdown-item @click="$emit('filter', 'all')">
                            <span>All</span>
                        </vs-dropdown-item>
                        <vs-dropdown-item @click="$emit('filter', 'code_not_sent')">
                            <span>Invitation Not Sent</span>
                        </vs-dropdown-item>
                        <vs-dropdown-item @click="$emit('filter', 'code_sent')">
                            <span>Invitation Sent</span>
                        </vs-dropdown-item>
                        <vs-dropdown-item @click="$emit('filter', 'code_clicked')">
                            <span>Code Clicked</span>
                        </vs-dropdown-item>

                        <vs-dropdown-item @click="$emit('filter', 'code_used')">
                            <span>Account Created</span>
                        </vs-dropdown-item>
                        <vs-dropdown-item
                            @click="$emit('filter', 'code_completed')"
                        >
                            <span>Will Completed</span>
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>


            </div>


            <template slot="thead">
                <vs-th sort-key="name">Name</vs-th>
                <vs-th sort-key="date_added">Date Added</vs-th>
                <vs-th sort-key="name">Contact Details</vs-th>
                <vs-th sort-key="name">Share People Contact Details</vs-th>
                <vs-th :sort-key="statusSortKey">Status</vs-th>
                <vs-th>Actions</vs-th>
            </template>

            <template slot-scope="{ data }">
                <tbody>
                <vs-tr
                    :data="client"
                    :key="index"
                    v-for="(client, index) in data"
                >
                    <vs-td> {{ client.name }}<br /></vs-td>
                    <vs-td>
                        {{ formatDateAndTime(client.date_added) }}
                    </vs-td>
                    <vs-td>
                        <p>
                            <small
                            >{{ client.email1 }}
                                <feather-icon
                                    v-if="client.email1_sent"
                                    class="ml-1"
                                    icon="CheckIcon"
                                    svgClasses="h-3 w-3 bg-success rounded-full text-white"
                                ></feather-icon>
                                <feather-icon
                                    v-if="client.email1_status"
                                    class="ml-1"
                                    icon="XIcon"
                                    color="danger"
                                    svgClasses="h-3 w-3 bg-secondary rounded-full text-white"
                                ></feather-icon>
                                <span
                                    class="text-danger"
                                    v-if="client.email1_status"
                                ><br />{{ client.email1_status }}</span
                                >
                            </small>
                        </p>
                        <p>
                            <small
                            >{{ client.email2 }}
                                <feather-icon
                                    v-if="client.email2_sent"
                                    class="ml-1"
                                    icon="CheckIcon"
                                    svgClasses="h-3 w-3 bg-success rounded-full text-white"
                                ></feather-icon>
                                <feather-icon
                                    v-if="client.email2_status"
                                    class="ml-1"
                                    icon="XIcon"
                                    color="danger"
                                    svgClasses="h-3 w-3 bg-secondary rounded-full text-white"
                                ></feather-icon>
                                <span
                                    class="text-danger"
                                    v-if="client.email2_status"
                                ><br />{{ client.email2_status }}</span
                                >
                            </small>
                        </p>
                        <p>
                            <small
                            >{{ client.phone1 }}
                                <feather-icon
                                    v-if="client.phone1_sent"
                                    class="ml-1"
                                    icon="CheckIcon"
                                    svgClasses="h-3 w-3 bg-success rounded-full text-white"
                                ></feather-icon>
                                <feather-icon
                                    v-if="client.phone1_status"
                                    class="ml-1"
                                    icon="XIcon"
                                    svgClasses="h-3 w-3 bg-secondary rounded-full text-white"
                                ></feather-icon>
                                <span
                                    class="text-danger"
                                    v-if="client.phone1_status"
                                ><br />{{ client.phone1_status }}</span
                                >
                            </small>
                        </p>
                        <p>
                            <small
                            >{{ client.phone2 }}
                                <feather-icon
                                    v-if="client.phone2_sent"
                                    class="ml-1"
                                    icon="CheckIcon"
                                    svgClasses="h-3 w-3 bg-success rounded-full text-white"
                                ></feather-icon>
                                <feather-icon
                                    v-if="client.phone2_status"
                                    class="ml-1"
                                    icon="XIcon"
                                    svgClasses="h-3 w-3 bg-secondary rounded-full text-white"
                                ></feather-icon>
                                <span
                                    class="text-danger"
                                    v-if="client.phone2_status"
                                ><br />{{ client.phone2_status }}</span
                                >
                            </small>
                        </p>
                    </vs-td>
                    <vs-td>
                        <template v-if="client.share_people.length">
                            <p class='text-sm' v-for='(person, index) in client.share_people'
                               :key='index'>

                                {{ role(person) }} - {{ person.full_name }} - {{ person.email }} {{ person.phone }}
                                <br><br>
                            </p>
                        </template>

                        <small v-else>No client share details collected</small>

                    </vs-td>
                    <vs-td>
                        <vx-tooltip
                            v-if="client.code_completed"
                            :text="formatDate(client.code_completed_date)"
                            title="Will Created"
                            color="primary"
                        >
                            <feather-icon icon="FileIcon"></feather-icon>
                        </vx-tooltip>
                        <vx-tooltip
                            v-else-if="client.code_used"
                            :text="formatDate(client.code_used_date)"
                            title="Account Created"
                            color="primary"
                        >
                            <feather-icon icon="UserIcon"></feather-icon>
                        </vx-tooltip>
                        <vx-tooltip
                            v-else-if="client.code_clicked"
                            :text="formatDate(client.code_clicked_date)"
                            title="Code Clicked"
                            color="primary"
                        >
                            <feather-icon
                                icon="MousePointerIcon"
                            ></feather-icon>
                        </vx-tooltip>
                        <vx-tooltip
                            v-else-if="client.code_sent"
                            :text="formatDate(client.code_sent_date)"
                            title="Invitation Sent"
                            color="primary"
                        >
                            <feather-icon icon="SendIcon"></feather-icon>
                        </vx-tooltip>

                    </vs-td>
                    <vs-td>
                        <vs-button v-if="client.coupon && !client.code_completed" @click="resendVoucherConfirm(client)">
                            Resend Voucher
                        </vs-button>
                    </vs-td>
                </vs-tr>

                </tbody>
            </template>
        </vs-table>
        <vs-popup
            title="Introducer Terms and Conditions"
            class="vs-con-loading__container"
            id="loading-download-clients"
        >
            <div id="body" class="main-container terms-container">
                <div class="section terms-sec" id="1">
                    <h3 class="mb-6 text-primary">1. Definitions</h3>
                    <p>
                        These definitions apply unless the context requires a
                        different interpretation:
                    </p>
                    <ul>
                        <li>
                            <span>"Confidential Information"</span> means all
                            information about the parties to this agreement,
                            including any information which may give a commercially
                            competitive advantage to any other person. It does not
                            include information that it is reasonably necessary to
                            disclose to a customer or other person in the usual
                            course of business so far as that information is
                            disclosed in those circumstances. It includes among
                            other things: data or information relating to product
                            plans, marketing strategies, business plans, and
                            internal performance results of a party, and the
                            customers and suppliers of any of them; It includes
                            information about the Intellectual Property and the
                            Know-how.
                        </li>

                        <li>
                            <span>"Intellectual Property"</span> means intellectual
                            property owned by the Company, of every sort, whether or
                            not registered or registrable in any country, including
                            intellectual property of kinds coming into existence
                            after today; and including, among others, patents,
                            trademarks, unregistered marks, designs, copyrights,
                            software, domain names, discoveries, Know-how, creations
                            and inventions, together with all rights which are
                            derived from those rights.
                        </li>
                        <li>
                            <span>"Know-how"</span> means methods, procedures and
                            ways of working and organising which are not capable of
                            protection as copyright.
                        </li>
                        <li>
                            <span>"Net Sales Receipts"</span> means the money
                            receivable for sales of the Products after deduction of
                            any sales tax and service provider costs charged by any
                            person relating to the payment or transfer of money
                            until it reaches the bank account of the Company.
                        </li>
                        <li>
                            <span>"Product"</span> means any product, goods or
                            service offered for sale from time to time by the
                            Company to the Introducer.
                        </li>
                        <li><span>"Territory"</span>means the United Kingdom</li>
                    </ul>
                </div>
            </div>
        </vs-popup>
    </div>
</template>

<script>
import { http } from '@/services'
import { textFormat } from '@/mixins/textFormat'

import DatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
    name: 'ClientsTable',
    mixins: [textFormat],
    props: ['data', 'itemsPerPage', 'view'],
    components: { DatePicker },
    computed: {
        accounts() {
            return this.data.data
        },
        filterLabel() {
            if (this.view === 'all') return 'All'
            if (this.view === 'code_clicked') return 'Code Clicked'
            if (this.view === 'code_not_sent') return 'Invitation Not Sent'
            if (this.view === 'code_sent') return 'Invitation Sent'
            if (this.view === 'code_used') return 'Account Created'
            if (this.view === 'code_completed') return 'Will Created'
            return ''
        },
    },
    watch: {
        range: {
            immediate: true,
            deep: true,
            handler(valueNew, valueOld) {
                if (valueNew) {
                    this.$emit('date', [valueNew.start, valueNew.end])
                }
            },
        },
    },
    data() {
        return {
            selectedClientAction: null,
            statusSortKey: 'code_completed',
            selected: [],
            searchQuery: '',
            status: 'All',
            selectMultiple: false,
            filter: 'all',
            range: null,
        }
    },
    methods: {
        downloadClients() {
            this.$vs.loading()

            // params don't do anything on server yet
            http.post('clients_report', { view: this.view }, {
                responseType: 'arraybuffer',
            })
                .then((response) => {
                    const filename = `client-report.csv`
                    this.openFile(response, filename)
                    this.$vs.notify({
                        title: 'Success',
                        text: 'File created',
                        color: 'success',
                        icon: 'feather',
                        iconPack: 'icon-save',
                        position: 'top-center',
                    })
                    this.$vs.loading.close()
                })
                .catch((error) => {
                    this.$vs.loading.close()
                    this.$vs.notify({
                        title: 'Error',
                        text: 'Problem downloading client list, please contact support.',
                        color: 'danger',
                        icon: 'feather',
                        iconPack: 'icon-save',
                        position: 'top-center',
                    })
                })

            console.log('done')
        },
        openFile(response, filename) {
            const type = response.headers['content-type']
            const blob = new Blob([response.data], {
                type: type,
                encoding: 'UTF-8',
            })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = filename
            link.click()
        },
        search(value) {
            if (value.length > 3 || value === '') {
                this.searchQuery = value
                this.$emit('search', value)
            }
        },
        createMirrorWill(client) {
            this.$root.$emit('createMirrorWill', client.email)
            window.scrollTo(0, 0)
        },
        handleSort(key, value) {
            this.$emit('sort', [key, value])
        },
        role(person) {
            let string = ''
            if (person.executor_sole_primary || person.executor_primary || person.executor_secondary) string += 'Executor'
            if (person.executor_backup) string += 'Backup Executor'
            if (person.trustee_sole_primary || person.trustee_primary || person.trustee_secondary) {
                if (string !== '') string += ' & '
                string += 'Trustee'
            }
            if (person.trustee_backup) {
                if (string !== '') string += ' & '
                string += 'Backup Trustee'
            }
            if (person)
                return string
        },
        resendVoucherConfirm(client) {
            this.selectedClientAction = client
            this.$vs.dialog({
                type: 'confirm',
                color: 'secondary',
                title: `Resend Voucher Confirm`,
                text: `Would you like to resend voucher to ${client.name} on
        ${client.phone1 ? client.phone1 : ''} ${client.phone2 ? client.phone2 : ''}
        ${client.email1 ? client.email1 : ''} ${client.email2 ? client.email2 : ''}?`,
                accept: this.resendVoucher,
                acceptText: 'Resend Voucher',
            })
        },
        resendVoucher() {
            let data = {
                client: this.selectedClientAction.id,
            }

            http.post('resend_single', data)
                .then((response) => {
                    this.$vs.notify({
                        title: 'Success',
                        text: 'Voucher resent',
                        color: 'success',
                        icon: 'feather',
                        iconPack: 'icon-send',
                        position: 'top-center',
                    })
                    this.$emit('refresh')
                })
                .catch((error) => {
                    this.$vs.notify({
                        title: 'Error',
                        text: error.response.data,
                        color: 'warning',
                        icon: 'feather',
                        iconPack: 'icon-trash',
                        position: 'top-center',
                    })
                })
        },
    },
}
</script>

<style>
.row-hidden-elements {
    visibility: hidden;
}

.vs-table tr:hover .row-hidden-elements {
    visibility: visible;
}
</style>
